// import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'
import {Events} from '../components/Events'
import { components } from '../slices'
import { Pagination } from '../utils/pagination'

const EventsTemplate = (props) => {
  const { data, pageContext } = props;
  // console.log('EventsTemplate data: ', data);
  
  const [events,setEvents] = useState([])

  React.useEffect(() => {
    console.log('did mount props: ', props);
    const _events = pageContext.events || [];

    const now = moment().utc()
    const _sorted = _events.map(item => {
      // console.log('item: ', item)
      item.data.startTime = moment(item.data.start_time).unix();
      item.data.finishTime = moment(item.data.finish_time).unix();
      const finishUtc = moment(item.data.finish_time).tz("Europe/Berlin")
      const isOver = now.isAfter(finishUtc)
      item.data.isOver = isOver;
      return item;
    }).sort((a,b) => {
      return a.data.startTime - b.data.startTime;
    });
    const _before = _sorted.filter(el => el.data.isOver === false);
    // const _after = _sorted.filter(el => el.data.isOver === true).reverse();
    const _after = _sorted.filter(el => el.data.isOver === true).sort((a,b) => {
      return b.data.startTime - a.data.startTime;
    });
    // console.table(_sorted);
    // _after.find((el, index) => {
    //   console.log(`start:${el.data.start_time} <:> ${el.data.finish_time}`);
    //   if (index > 9) return true;
    // });
    const _all = [..._before, ..._after];
    const {skip, limit} = pageContext;
    const filtered = _all.slice(skip, limit+skip);
    setEvents(filtered)
}, []);

  // useEffect( ()=> {
  //   const docs = data.allPrismicEvent || []
  //   // src/components/Events.js
  //   const now = moment().utc()
  //   const _filtered = docs.nodes.map(item => {
  //     // console.log('item: ', item)
  //     item.data.startTime = moment(item.data.start_time).unix();
  //     const finishUtc = moment(item.data.finish_time).tz("Europe/Berlin")
  //     const isOver = now.isAfter(finishUtc)
  //     item.data.isOver = isOver;
  //     return item;
  //   }).sort((a,b) => {
  //     return a.data.startTime - b.data.startTime;
  //   });
  //   const over_1 = _filtered.filter(item => {
  //     return item.data.isOver;
  //   });
  //   const over_2 = _filtered.filter(item => {
  //     return !item.data.isOver;
  //   });
  //   const filtered = [...over_2, ...over_1];
  //   setEvents(filtered)
  // }, [data.allPrismicEvent])
  
  if (!data) return null

  const homepage = data.prismicEvents || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }
  console.log('seo: ', seo)

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const {pageInfo} = data.allPrismicEvent;

  const docs = pageContext.events || []; 
  docs.events = events
  // console.log('events: ', events)

  // return (
  //   <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
  //     <SliceZone slices={homepage.data.body} components={components} />
  //   </Layout>
  // )
  // return (
  //   <Layout>
  //     {/* <div className="home-header container" data-wio-id={home.id}>
  //       <div className="blog-avatar" style={avatar} />
  //       <h1>{home.headline.text}</h1>
  //       <p className="blog-description">{home.description.text}</p>
  //     </div> */}
  //     <Events docs={docs} />
  //   </Layout>
  // )
  return (
    <Layout topMenu={topMenu.data} footer={footer} activeDocMeta={activeDoc} seo={seo}>
      <div className="bg-light" style={{backgroundImage:"url(" + homepage.data.title_section_image.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",  backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{homepage.data.title.text}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <Events docs={docs} />
        <Pagination pageInfo={pageInfo} base={'events'}/>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query myEventsQuery($lang: String, $limit: Int!, $skip: Int!) {
    prismicEvents(lang: { eq: $lang }) {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        title_section_image {
          url
        }
        title {
          text
        }
        description {
          richText
        }
      }
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
    }

  prismicTopMenu(lang: { eq: $lang }) {
    ...TopMenuFragment
  }
  prismicFooter {
    ...FooterFragment
  }

  allPrismicEvent(
    sort: {fields: data___start_time, order: DESC}
    limit: $limit
    skip: $skip
    #filter: {
			#data:{
        #finish_time:{gte: $now}
      #}
    #}
  ) {
    nodes {
      _previewable
      uid
      # url
      # data {
      #   title {
      #     text
      #   }
      #   start_time
      #   finish_time
      #   body {
      #     ... on PrismicEventDataBodyEventInfo {
      #       id
      #       slice_label
      #       slice_type
      #       items {
      #         ... on PrismicEventDataBodyEventInfoItem {
      #           image {
      #             url
      #           }
      #         }
      #       }
      #       primary {
      #         ... on PrismicEventDataBodyEventInfoPrimary {
      #           description {
      #             text
      #           }
      #         }
      #       }
      #     }
      #   }
      # }
    }
    pageInfo {
      currentPage
      pageCount
    }
  }
}
`

export default withPrismicPreview(EventsTemplate)
